@import 'src/styles/images';

$cursor-size: .5rem;

:root {
  --cursor-fill-color: var(--red);
}

.presentation-wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.presentation {
  display: flex;
  height: 100%;
  width: 100%;

  .cursor {
    fill: var(--cursor-fill-color);
    r: $cursor-size;
  }
}

svg {
  height: 100%;
  width: 100%;
}
