@import 'src/styles/sizes';

:root {
  --poll-bar-color: var(--secondary-content-color);
}

.poll-options,
.poll-question,
.poll-result {
  padding-bottom: $padding-small;
}

.poll-bar {
  color: var(--poll-bar-color);
}

.poll-label {
  font-weight: var(--font-weight-semi-bold);
}
