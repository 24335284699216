@import 'src/styles/icons';

[class^="icon-"],
[class*=" icon-"] {
  align-items: center;
  display: flex;
  font-family: 'icons';
  height: 100%;
  justify-content: center;
}

.icon-left:before {
  content: "\e90d";
}

.icon-right:before {
  content: "\e90a";
}

.icon-captions:before {
  content: "\e957";
}

.icon-chat:before {
  content: "\e910";
}

.icon-check:before {
  content: "\e946";
}

.icon-close:before {
  content: "\e912";
}

.icon-fullscreen:before {
  content: "\e92a";
}

.icon-notes:before {
  content: "\e94a";
}

.icon-polls:before {
  content: "\e927";
}

.icon-presentation:before {
  content: "\e90b";
}

.icon-restore:before {
  content: "\e935";
}

.icon-screenshare:before {
  content: "\e928";
}

.icon-search:before {
  content: "\e958";
}

.icon-settings:before {
  content: "\e92b";
}

.icon-shortcuts:before {
  content: "\e94b";
}

.icon-swap:before {
  content: "\e949";
}

.icon-users:before {
  content: "\e905";
}

.icon-videos:before {
  content: "\e930";
}

.icon-webcams:before {
  content: "\e930";
}

.icon-dark:before {
  content: "\e9A0";
}

.icon-light:before {
  content: "\e9A1";
}
