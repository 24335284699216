@import 'src/styles/sizes';

.chat-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .list {
    font-size: small;
    height: 100%;
    position: relative;
    width: 100%;
  }
}

.message-wrapper {
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $padding 0 0 0;
  position: absolute;
  scroll-behavior: smooth;
  top: 0;
  width: 100%;
}
