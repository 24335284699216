@import 'src/styles/colors';
@import 'src/styles/fonts';
@import 'src/styles/sizes';

root {
  --body-background-color: var(--primary-background-color);
}

body,
html {
  height: 100%;
  width: 100%;
}

body {
  background-color: var(--body-background-color);
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  margin: 0;
  overflow: hidden;
}

#root {
  height: 100%;
  width: 100%;
}
