$avatar-size: 2rem;

:root {
  --avatar-color: var(--white);
  --avatar-default-background-color: var(--blue);
  --avatar-zero-background-color: rgb(123, 31, 162);
  --avatar-one-background-color: rgb(106, 27, 154);
  --avatar-two-background-color: rgb(74, 20, 140);
  --avatar-three-background-color: rgb(94, 53, 177);
  --avatar-four-background-color: rgb(81, 45, 168);
  --avatar-five-background-color: rgb(69, 39, 160);
  --avatar-six-background-color: rgb(49, 27, 146);
  --avatar-seven-background-color: rgb(57, 73, 171);
  --avatar-eight-background-color: rgb(48, 63, 159);
  --avatar-nine-background-color: rgb(40, 53, 147);
  --avatar-ten-background-color: rgb(26, 35, 126);
  --avatar-eleven-background-color: rgb(25, 118, 210);
  --avatar-twelve-background-color: rgb(21, 101, 192);
  --avatar-thirteen-background-color: rgb(13, 71, 161);
  --avatar-fourteen-background-color: rgb(2, 119, 189);
  --avatar-fifteen-background-color: rgb(1, 87, 155);
}

.avatar-wrapper {
  display: flex;
  font-size: medium;
  height: 100%;

  .avatar {
    color: var(--avatar-color);
    height: $avatar-size;
    text-align: center;
    width: $avatar-size;

    .initials {
      align-items: center;
      display: flex;
      font-size: small;
      height: 100%;
      justify-content: center;
      text-transform: capitalize;
      width: 100%;
    }
  }

  .circle {
    border-radius: 50%;
  }

  .avatar-default {
    background-color: var(--avatar-default-background-color);
  }

  .avatar-zero {
    background-color: var(--avatar-zero-background-color);
  }

  .avatar-one {
    background-color: var(--avatar-one-background-color);
  }

  .avatar-two {
    background-color: var(--avatar-two-background-color);
  }

  .avatar-three {
    background-color: var(--avatar-three-background-color);
  }

  .avatar-four {
    background-color: var(--avatar-four-background-color);
  }

  .avatar-five {
    background-color: var(--avatar-five-background-color);
  }

  .avatar-six {
    background-color: var(--avatar-six-background-color);
  }

  .avatar-seven {
    background-color: var(--avatar-seven-background-color);
  }

  .avatar-eight {
    background-color: var(--avatar-eight-background-color);
  }

  .avatar-nine {
    background-color: var(--avatar-nine-background-color);
  }

  .avatar-ten {
    background-color: var(--avatar-ten-background-color);
  }

  .avatar-eleven {
    background-color: var(--avatar-eleven-background-color);
  }

  .avatar-twelve {
    background-color: var(--avatar-twelve-background-color);
  }

  .avatar-thirteen {
    background-color: var(--avatar-thirteen-background-color);
  }

  .avatar-fourteen {
    background-color: var(--avatar-fourteen-background-color);
  }

  .avatar-fifteen {
    background-color: var(--avatar-fifteen-background-color);
  }
}
