@import 'src/styles/sizes';

.data-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow-x: hidden;
  overflow-y: auto;

  .item {
    box-sizing: border-box;
    color: var(--loader-color);
    display: flex;
    font-weight: var(--font-weight-semi-bold);
    padding: $padding;
    opacity: .25;
  }

  .loaded {
    opacity: 1;
  }
}
