:root {
  --gray-darkest: rgb(17, 17, 17);
  --gray-darker: rgb(6, 23, 42);
  --gray-dark: rgb(51, 51, 51);
  --gray: rgb(78, 90, 102);
  --gray-light: rgb(139, 154, 168);
  --gray-lighter: rgb(167, 179, 189);
  --gray-lightest: rgb(212, 217, 223);

  --blue: rgb(15, 112, 215);

  --red: rgb(223, 39, 33);

  --green: rgb(0, 128, 129);

  --white-dark: rgb(243, 246, 249);
  --white: rgb(255, 255, 255);

  --primary-background-color: var(--white-dark);
  --primary-highlight-color: var(--gray-lightest);
  --primary-content-color: var(--gray-dark);

  --secondary-background-color: var(--white);
  --secondary-highlight-color: var(--gray-lightest);
  --secondary-content-color: var(--gray-dark);

  --tertiary-background-color: var(--gray-lightest);
  --tertiary-highlight-color: var(--gray-lighter);
  --tertiary-content-color: var(--gray-dark);
}
