$control-bar-height: 30px;
$media-min-width: 480px;
$media-max-height: 400px;

$modal-max-width: 320px;

$webcams-height: 480px;
$webcams-width: 640px;

$section-width: calc(#{$webcams-width} / 2);
$section-width-small: calc((#{$webcams-width} / 8) * 3);

$bottom-content-height: 80px;

$bar-height: 60px;

$top-bar-height: $bar-height;
$bottom-bar-height: calc(#{$bar-height} / 3);
$modal-bar-height: $bar-height;

$media-fit-height: calc((#{$webcams-height} / 2) - #{$bar-height});
$media-fit-height-small: calc(((#{$webcams-height} / 8) * 3) - #{$bar-height});

$shortcuts-list-height: 8rem;

$border-radius: .35rem;
$border-width: .2rem;
$border-width-small: .15rem;
$border-width-extra-small: .1rem;
$margin: .5rem;
$margin-small: .35rem;
$margin-extra-small: .15rem;
$padding: .75rem;
$padding-small: .5rem;
$padding-extra-small: .25rem;
