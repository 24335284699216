.about-body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.body-shortcuts {
  display: flex;
  width: 100%;
}
