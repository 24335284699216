@import 'src/styles/sizes';

.about-header,
.about-footer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
}

.about-header {
  flex-direction: column;
  font-weight: var(--font-weight-regular);
  padding: 0 0 $padding 0;

  .title,
  .subtitle {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: larger;
    overflow: hidden;
    padding: 0 0 $padding-extra-small 0;
    text-overflow: ellipsis;
  }

  .subtitle {
    font-size: small;
    padding: 0 $padding-small;
    opacity: .75;

    .item {
      box-sizing: border-box;
      padding: 0 $padding-extra-small;
    }
  }
}

.about-footer {
  align-items: center;
  justify-content: flex-end;
  padding: $padding;

  .revision {
    box-sizing: border-box;
    font-size: small;
    opacity: .75;
    padding: $padding 0 0 0;
  }
}
