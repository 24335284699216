@import 'src/styles/sizes';

:root {
  --system-message-wrapper-background-color: var(--secondary-highlight-color);
}

.system-message-wrapper {
  background-color: var(--system-message-wrapper-background-color);
  padding: $padding-small $padding-small $padding-small $padding-small;

  [dir="ltr"] & {
    margin: 0 $margin 0 0;
  }

  [dir="rtl"] & {
    margin: 0 0 0 $margin;
  }
}
