.logo {
  background-image: url(./assets/logo.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.thumbnail {
  .logo {
    background-size: contain;
  }
}
