@import 'src/styles/sizes';

:root {
  --control-bar-background-color: var(--gray-dark);
}

.webcams-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.video-js {
  cursor: pointer;
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
}

.video-js .vjs-big-play-button,
.vjs-text-track-display,
.vjs-loading-spinner,
.video-js .vjs-modal-dialog {
  position: fixed;
  z-index: 2;
}

.video-js .vjs-big-play-button {
  border: none;
  border-radius: 0;
  line-height: 1.65em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.vjs-text-track-display,
.video-js .vjs-modal-dialog {
  height: 100%;
  width: 100%;
}

.video-js .vjs-control-bar {
  background-color: var(--control-bar-background-color);
  display: flex;
  position: fixed;
  z-index: 1;
}

.vjs-menu-button-popup {
  .vjs-menu {
    left: unset !important;
    right: 0 !important;
  }
}

[class^="vjs-text-track-cue"] {
  inset: auto 0 calc(#{$control-bar-height} * 2) 0 !important;
  z-index: 2;
}
