@import 'src/styles/images';
@import 'src/styles/sizes';

$thumbnail-height: calc((#{$bottom-content-height} / 10) * 6);

.thumbnails-wrapper {
  display: flex;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  scroll-behavior: smooth;
  width: 100%;
}

.thumbnail-wrapper {
  box-sizing: border-box;
  display: flex;
  padding: $padding-extra-small;

  .thumbnail {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .thumbnail-image {
      display: block;
      height: $thumbnail-height;
      width: auto;
    }

    .thumbnail-index {
      display: flex;
      font-size: x-small;
      font-weight: var(--font-weight-bold);
      margin-top: $margin-extra-small;
    }

    .screenshare {
      font-size: xxx-large;
      font-weight: var(--font-weight-semi-bold);
    }
  }
}
