@import 'src/styles/sizes';

.body-shortcuts {
  display: flex;
  flex-direction: column;

  .title {
    background-color: var(--secondary-highlight-color);
    box-sizing: border-box;
    display: flex;
    font-size: smaller;
    font-weight: var(--font-weight-semi-bold);
    justify-content: center;
    padding: $padding-extra-small;
    text-transform: uppercase;
    width: 100%;
  }

  .list {
    height: $shortcuts-list-height;
    position: relative;
    width: 100%;
  }

  .content {
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    font-size: small;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $padding-extra-small;
    position: absolute;
    scroll-behavior: smooth;
    top: 0;
    width: 100%;

    .shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      padding: $margin-extra-small 0;

      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .keys {
        display: flex;

        .key {
          background-color: var(--secondary-content-color);
          color: var(--secondary-background-color);
          font-size: xx-small;
          margin: 0 $margin-extra-small;
          padding: $border-width-extra-small $border-width;
          text-transform: uppercase;
        }
      }
    }
  }
}
